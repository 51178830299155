<template>
  <div class="goods-list">
    <div
      class="list"
      v-for="(item, i) in list"
      :key="i"
      @click="toDetail(item)"
    >
      <div class="img">
        <img :src="item.MainImage" alt="" />
      </div>
      <div v-if="discount" class="discount">
        <div>{{ item.percent || 0 }} %</div>
        <div>OFF</div>
      </div>
      <div class="price">${{ item.SalePrice }}</div>
      <div class="count" v-if="!hideCount">{{ $t("xiao-shou-liang") }}{{ item.Sales }}</div>
      <div class="title">
        {{ item.ProductTranslation ? item.ProductTranslation[0].Name : '' }}
      </div>
      <div class="flex-center-between action">
        <div class="flex-center">
          <i class="el-icon-shopping-cart-full mr-5"></i>
          {{ $t("li-ji-gou-mai") }}
        </div>
        <div>
          <i class="el-icon-star-off" v-if="!item.isCollect"></i>
          <i class="el-icon-star-on" v-else></i>
        </div>
      </div>
    </div>

    <div class="empty-box" v-if="list.length === 0">
      <img :src="emptyUrl" alt="" />
      <span>{{ $t("zan-wu-shu-ju") }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      emptyUrl: require('@/assets/imgs/emtpy.svg')
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    discount: {
      type: Boolean,
      default: false
    },
    hideCount: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID
        }
      })
    }
  }
}
</script>